/**
 * 微信权限验证
 */
import {getWxsdk} from "@/api/register";
import wx from "jweixin-js";


export function verification(jsApiList) {
    return new Promise((resolve, reject) => {
        getWxsdk({url: escape(window.entryUrl)}).then(res => {
            let obj = res.data

            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: obj.appId, // 必填，公众号的唯一标识
                timestamp: obj.timestamp, // 必填，生成签名的时间戳
                nonceStr: obj.nonceStr, // 必填，生成签名的随机串
                signature: obj.signature,// 必填，签名
                jsApiList// 必填，需要使用的JS接口列表
            });
            wx.ready(res => {
                console.log(`%c wx.ready`, 'color:red;font-size:20px', res)
                resolve()
            });
            wx.error(function (err) {
                console.log(`%c wx.error`, 'color:red;font-size:20px', err)
                reject()
            });
        })
    })
}

export default wx
