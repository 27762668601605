import request from "@/utils/http";

export function getOrderClaimsInfo(id) {
    return request({
        url: '/Claims/getOrderClaimsInfo?claims_id='+id,
        method: 'get',
    })
}

export function setRepairFinish(params = {},openid) {
    return request({
        url: '/Repair/setRepairFinish',
        method: 'post',
        data: params,
        openid
    })
}
export function finishOrder(id) {
    return request({
        url: '/Repair/finishOrder?finish_id='+id,
        method: 'get',
    })
}

export function getOpenid(params = {}) {
    return request({
        url: '/index/getOpenid',
        method: 'get',
        params: params
    })
}