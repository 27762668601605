<template>
  <div class="region-selector">
    <van-cell :title="title" is-link border @click="isShow = !isShow">
      <template #default>
        <!--        <input type="text" class="cell-input" v-model="value_" :placeholder="placeholder"/>-->
        <div class="value" :style="{ color: value_ ? '' : '#999' }">{{ value_ || placeholder }}</div>
      </template>
    </van-cell>
    <!--  van-cell后面有标签才会出现下划线  -->
    <div></div>
    <van-popup v-model="isShow" round position="bottom">
      <van-cascader v-model="fieldValue" title="请选择所在地区" :options="options" @close="isShow = false" @change="change" />
    </van-popup>
  </div>
</template>

<script>
import { getRegion } from "@/api/register";
import { getRegion2 } from "@/api/register";

export default {
  model: {
    prop: 'value',
    event: 'finish'
  },
  props: {
    isRong: {//蓉家保用getRegion2
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '地区选择'
    },
    value: {
      type: [String, Array],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择地区'
    },
  },
  name: "RegionSelector",
  data() {
    return {
      value_: '',
      isShow: false,
      options: [],
      fieldValue: '',
      tabIndex: 0,
    }
  },
  mounted() {
    if (this.isRong) {
      this.getRegion2(0, 1).then(options => {
        this.options = options
      })
    } else {
      this.getRegion(0).then(options => {
        this.options = options
      })
    }
  },
  methods: {
    getRegion(pid) {
      return getRegion({ pid }).then(res => {
        let data = res.data || {}
        let options = []
        for (let key in data) {
          options.push({
            text: data[key],
            value: key,
            children: []
          })
        }
        return options
      })
    },
    getRegion2(pid, type) {
      return getRegion2({ pid, type }).then(res => {
        let data = res.data || {}
        let options = []
        if (this.tabIndex !== 1) {
          for (let key in data) {
            options.push({
              text: data[key],
              value: key,
              children: []
            })
          }
          return options
        } else {
          for (let key in data) {
            options.push({
              text: data[key],
              value: key,
              // children: []
            })
          }
          return options
        }
      })
    },
    change({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 2) {
        this.value_ = selectedOptions.map((option) => option.text).join('/');
        this.$emit('finish', selectedOptions)
        this.isShow = false
        return
      }
      this.tabIndex = tabIndex
      console.log(tabIndex, 'tabIndex');
      console.log(value, 'value');
      if (this.isRong) {
        let rongtype = ''
        if (tabIndex === 0) {
          rongtype = 2
        }
        if (tabIndex === 1) {
          rongtype = 3
        }
        this.getRegion2(value, rongtype).then(options => {
          // if (!options.length) {
          //   this.$emit('finish')
          //   this.isShow = false
          // }
          selectedOptions[tabIndex].children = options;
        })
      } else {
        this.getRegion(value).then(options => {
          // if (!options.length) {
          //   this.$emit('finish')
          //   this.isShow = false
          // }
          selectedOptions[tabIndex].children = options;
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.cell-input {
  text-align: right;
  background: transparent;
}
</style>
