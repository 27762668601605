import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import './common'
import API from '@/api'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import * as filters from './common/filters.js'

let options = {
 fullscreenEl: false, //控制是否显示右上角全屏按钮
 closeEl: false, //控制是否显示右上角关闭按钮
 tapToClose: true, //点击滑动区域应关闭图库
 shareEl: false, //控制是否显示分享按钮
 zoomEl: false, //控制是否显示放大缩小按钮
 counterEl: false, //控制是否显示左上角图片数量按钮
 arrowEl: true,  //控制如图的左右箭头（pc浏览器模拟手机时）
 tapToToggleControls: true, //点击应切换控件的可见性
 clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
};
Vue.use(preview, options)
Object.keys(filters).forEach(key=>{
  Vue.filter(key,filters[key])
})

Vue.prototype.$http = API
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title||'亚丁';
  }
  next();
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

