<template>
  <div>
<!--    todo-->
    <van-field
        class="form_wid"
        readonly
        clickable
        :label="label"
        :value="typeof selectValue==='object'?selectValue[valueKey]:selectValue"
        is-link
        :label-width="labelWidth"
        input-align='right'
        :placeholder="placeholder"
        :disabled="disabled"
        @click="clickDiaShow"
    >
      <template #right-icon>
        <van-icon class="iconfont" class-prefix="icon" name="xiala" size="18" color="#CCCCCCC"/>
      </template>
    </van-field>
    <div>
      <van-popup v-model="show" round position="bottom">
        <van-picker
            :title="title"
            show-toolbar
            :columns="columns"
            :value-key="valueKey"
            @cancel="show = !show"
            :default-index="defaultIndex(selectValue)"
            @confirm="onConfirm"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fieldPiker',
  model: {
    prop: 'selectValue',
    event: 'getMessage'
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    title: {
      type: String
    },
    selectValue: {
      type: [String,Object,Number]
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    valueKey: {
      type: String
    },
    disabled:{
      type:Boolean,
      default:false
    },
    labelWidth:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    // defaultIndex() {
    //   return value => {
    //     return this.columns.findIndex(item => {
    //       return this.valueKey ? item[this.valueKey] === value[this.valueKey]:item===value
    //     })
    //   }
    // }
  },
  methods: {
    defaultIndex(value) {
      return this.columns.findIndex(item => {
        return this.valueKey ? item[this.valueKey] === value[this.valueKey]:item===value
      })
    },
    onConfirm(value) {
      this.value = value&&value.valueKey||'';
      this.show = !this.show;
      this.$emit("getMessage", value);
    },
    clickDiaShow() {
      if (this.disabled) {
        return
      }
      this.show = !this.show;
      this.$emit("clickDiaShow", this.show);
    }
  },
  watch: {}
};
</script>

<style scoped lang="less">

</style>
