<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import { getUserOpenid, checkOpenid, testce } from "@/api";
import { getOpenid } from "@/api/repairInfo";
import { getGroup } from "@/api/register";
export default {
  name: "app",
  created() {
    //计算屏幕缩放比
    this.$store.commit(
      "setScale",
      parseFloat(document.documentElement.style.fontSize) / 75
    );

    let domain = window.location.href.match(/(https?:\/\/)[^/]+(?=\/)/)[0];
    this.$router.beforeResolve((to, from, next) => {
      if (to.meta.noVerify) {
        next();
        return;
      }
      if (to.path === "/auth") {
        let fullPath = unescape(to.query.path);
        if (fullPath.indexOf("?") !== -1) {
          fullPath += "&code=" + to.query.code;
        } else {
          fullPath += "?code=" + to.query.code;
        }
        window.location.href = domain + fullPath;
        return;
      }

      if (!window.localStorage.getItem("openid") && !to.query.code) {
        //// -starty ------22.10.27
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
        //   process.env.VUE_APP_APPID
        // }&redirect_uri=${
        //   domain + "/auth?path=" + escape(to.fullPath)
        // }&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        // return;
        //////----end
        if (process.env.NODE_ENV === "production") {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
            process.env.VUE_APP_APPID
          }&redirect_uri=${
            domain + "/auth?path=" + escape(to.fullPath)
          }&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          return;
        } else {
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              subscribe: 1,
              // openid: "osYzy5hgqx-AxvB8xxkG5m9UMN40",
              openid: "oYqf7wguEfEENtmVavc1G-cbM9ZI",
              nickname: "J-Lee",
              sex: 1,
              language: "zh_CN",
              city: "泸州",
              province: "四川",
              country: "中国",
              headimgurl:
                "http://thirdwx.qlogo.cn/mmopen/meXK7N30t4cVkkibliaqwKj8u4PMErn273vzvXVsIDJFFjJiaSHBvUwCbpRCTTGOMicIqcB7uB2zT7CACv7lndjV5x5UlGwhicGeI/132",
              subscribe_time: 1622620253,
              remark: "",
              groupid: 0,
              tagid_list: [],
              subscribe_scene: "ADD_SCENE_PROFILE_CARD",
              qr_scene: 0,
              qr_scene_str: "",
              headimg:
                "http://thirdwx.qlogo.cn/mmopen/meXK7N30t4cVkkibliaqwKj8u4PMErn273vzvXVsIDJFFjJiaSHBvUwCbpRCTTGOMicIqcB7uB2zT7CACv7lndjV5x5UlGwhicGeI/132",
            })
          );
          localStorage.setItem("openid", "oYqf7wguEfEENtmVavc1G-cbM9ZI");
        }
      }

      let checkRole = () => {
        //如果是维修工程师页面，获取维修工程师openid
        if (to.meta.role) {
          // if ( to.name === 'RepairInfoAdd') {
          //   window.localStorage.clear()
          // }
          if (window.localStorage.getItem("openid")) {
            checkOpenid({
              openid: window.localStorage.getItem("openid"),
              type: to.meta.role === "repairman" ? 2 : 1,
            })
              .then(() => {})
              .catch((err) => {
                localStorage.clear();
                if (err && err.code === 401) {
                  this.$myDialog({
                    type: "error",
                    title:
                      to.meta.role === "repairman"
                        ? "未进行维修商注册"
                        : "未注册",
                    message:
                      to.meta.role === "repairman"
                        ? "首次登陆，需进行维修商注册请认真如实填写注册信息，谢谢！"
                        : "首次登陆，需进行门店归属绑定请认真如实填写注册信息，谢谢！",
                    confirmButtonText: "立即注册",
                    beforeClose: (action, done) => {
                      this.$router.push({
                        name: "Register",
                        params: { type: this.$store.state.role },
                      });
                      done();
                    },
                  });
                }
              });
          }

          this.$store.commit("setRole", to.meta.role);
        } else {
          this.$store.commit("setRole", "");
        }
      };

      checkRole();

      if (to.query.code && !window.localStorage.getItem("openid")) {
        this.getOpenidByCode(to.query.code)
          .then(() => {
            checkRole();
            next();
          })
          .catch(() => {
            next();
          });
      } else {
        next();
      }
      if (
        !this.$store.state.userInfo ||
        JSON.stringify(this.$store.state.userInfo) === "{}"
      ) {
        this.$store.commit(
          "setUserInfo",
          JSON.parse(window.localStorage.getItem("userInfo"))
        );
      }
      next();
    });
  },
  methods: {
    parseUrl(url) {
      url = url.replace(/#\/$/, "");
      let query = url.split("?")[1] || "";
      let params = query.split("&");
      params = params.length === 1 && params[0] === "" ? [] : params;
      let obj = {};
      params.forEach((item) => {
        let split = item.split("=");
        let key = split[0];
        obj[key] = split[1];
      });
      return obj;
    },
    buildAuthUrl(domain, path, query) {
      let url = domain + "/auth?path=";
      let queryString = "";
      Object.keys(query).forEach((key, index) => {
        if (index === 0) {
          queryString += "?" + key + "=" + query[key];
        } else {
          queryString += "&" + key + "=" + query[key];
        }
      });
      queryString = escape(path + queryString);
      url += queryString;
      return url;
    },
    getOpenidByCode(code) {
      return new Promise((resolve, reject) => {
        if (this.$store.state.role) {
          let type = this.$store.state.role === "repairman" ? 2 : 1;
          this.requestAndSendError(
            `/index/getOpenid?code=${code}&type=${type}`,
            {},
            "GET"
          )
            .then((res) => {
              let userInfo = res && res.data;
              if (userInfo) {
                window.localStorage.setItem("openid", userInfo.openid);
                window.localStorage.setItem(
                  "userInfo",
                  JSON.stringify(userInfo)
                );
                this.$store.commit("setUserInfo", userInfo);
                resolve();
              } else {
                localStorage.clear();
                this.$myDialog({
                  type: "error",
                  title: (res && res.msg) || "无法获取用户信息",
                });
                // this.$toast('无法获取用户信息')
                reject();
              }
            })
            .catch((err) => {
              localStorage.clear();
              if (err && err.code === 401) {
                this.$myDialog({
                  type: "error",
                  title: (err && err.msg) || "请前往注册",
                  beforeClose: (action, done) => {
                    window.localStorage.setItem(
                      "userInfo",
                      JSON.stringify(err.data)
                    );
                    window.localStorage.setItem("openid", err.data.openid);
                    this.$store.commit("setUserInfo", err.data);
                    this.$router.push({
                      name: "Register",
                      params: { type: this.$store.state.role },
                    });
                    done();
                  },
                });
              } else {
                this.$myDialog({
                  type: "error",
                  title: (err && err.msg) || "无法获取用户openid",
                  beforeClose: (action, done) => {
                    this.$router.push({ query: {} });
                    done();
                  },
                });
              }
              reject();
            });
        } else {
          //一般用户
          this.requestAndSendError("/index/getUserOpenid", { code })
            .then((res) => {
              let userInfo = res && res.data;
              if (userInfo) {
                window.localStorage.setItem(
                  "userInfo",
                  JSON.stringify(userInfo)
                );
                window.localStorage.setItem("openid", userInfo.openid);
                this.$store.commit("setUserInfo", userInfo);
                resolve();
              } else {
                localStorage.clear();
                this.$myDialog({
                  type: "error",
                  title: (res && res.msg) || "无法获取用户信息",
                });
                // this.$toast('无法获取用户信息')
                reject();
              }
            })
            .catch((err) => {
              localStorage.clear();
              this.$myDialog({
                type: "error",
                title: (err && err.msg) || "无法获取用户openid",
              });
              reject();
            });
        }
      });
    },
    requestAndSendError(path, params = {}, method = "POST") {
      return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest();
        request.open(method, process.env["VUE_APP_BASE_URL"] + "/api" + path);
        request.setRequestHeader("Content-type", "application/json");
        request.setRequestHeader(
          "openid",
          localStorage.getItem("openid") || ""
        );

        request.send(JSON.stringify(params));
        request.onload = (res) => {
          let data = JSON.parse(request.responseText);
          if (request.status === 200) {
            if (data.code === 0) {
              testce({
                res: data,
              });
              reject(data);
            } else {
              resolve(data);
            }
          } else {
            testce({
              res: res.currentTarget.responseText,
            });
            reject(data);
          }
        };
        request.onerror = (err) => {
          testce({
            res: err,
          });
          reject(JSON.parse(request.responseText));
        };
      });
    },
  },
};
</script>
<style lang="less">
#app {
  font-size: 32px;

  * {
    box-sizing: border-box;
  }
}
</style>
