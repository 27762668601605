<template>
  <div>
    <van-cell is-link border @click="show">
      <div class="value" :style="{color:!selectedOptions.length||disabled?'#999':''}">
        <template v-if="selectedOptions.length">
          <template v-if="!isCollapse">
            {{ selectedOptions.map(i=>(i[nameKey])).join(',') || placeholder + '(可多选)' }}
          </template>
          <template v-if="isCollapse">
            {{selectedOptions[0][nameKey]}}
            <span class="collapse" v-if="selectedOptions.length>1">+{{selectedOptions.length-1}}</span>
          </template>
        </template>
        <template v-else>
          {{ placeholder + '(可多选)' }}
        </template>
      </div>
      <template #title>
        <span class="cell-title" :style="{color:disabled?'#999':''}">{{ title }}</span>
      </template>
    </van-cell>
    <div></div>
    <van-popup v-model="isShow" round position="bottom">
      <div class="body">
        <div class="head">
          <span class="cancel" @click="isShow=false">取消</span>
          <div class="title">{{ title }} <span class="subtitle">(可多选)</span></div>
          <span class="confirm" @click="confirm">确定</span>
        </div>
        <div class="options">
          <div class="option" :class="{required:item[requiredKey]}" v-for="(item,index) in options" :key="index" @click="select(item)">
            <img v-if="selectedSet.has(item[idKey])" class="radio-icon" :src="require('@/assets/images/icon_dx.png')"
                 alt="">
            <div v-else class="not-selected"></div>
            <div class="name">{{ item[nameKey] }}</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "MultipleChoice",
  model: {
    prop: 'selectedOptions',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    nameKey: {
      type: String,
      default: 'name'
    },
    idKey: {
      type: String,
      default: 'id'
    },
    selectedOptions: {
      type: Array,
      default() {
        return []
      }
    },
    isCollapse: {
      type: Boolean,
      default:false
    },
    disabled:{
      type: Boolean,
      default:false
    },
    requiredKey:{
      type: String,
      default:''
    },
  },
  data() {
    return {
      isShow: false,
      // value: '',
      selectedSet: new Set()
    }
  },
  watch: {
    selectedOptions: {
      handler() {
        this.setSelected()
      },
      immediate: true,
      deep: true
    },
    isShow(isShow) {
      if (isShow) {
        this.setSelected()
      }
    }
  },
  methods: {
    show() {
      if (this.disabled) {
        return
      }
      this.isShow=!this.isShow;
    },
    setSelected() {
      let selectedOptions = this.selectedOptions || [];
      this.selectedSet = new Set(selectedOptions.map(item => {
        return item[this.idKey]
      }))
      // this.value = selectedOptions.map(item => (item[this.nameKey])).join(',')
    },
    select(item) {
      if (item[this.requiredKey]) {
        this.$toast('此项为必选！')
        return
      }
      let id = item[this.idKey];
      if (this.selectedSet.has(id)) {
        this.selectedSet.delete(id);
      } else {
        this.selectedSet.add(id);
      }
      this.$forceUpdate()
    },
    confirm() {
      let selectedOptions = this.options.filter(item => {
        return this.selectedSet.has(item[this.idKey])
      })
      this.$emit('change', selectedOptions);
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="less">
.cell-subtitle {
  font-size: 24px;
  position: relative;
  bottom: -2px;
}

.body {
  padding: 0 45px 100px;
}

.head {
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: space-between;
}

.cancel {
  font-size: 30px;
  color: #666;
}

.confirm {
  font-size: 30px;
  color: @base-color;
}

.title {
  font-size: 36px;
  font-weight: 800;
  color: #333333;
}

.subtitle {
  font-size: 26px;
}

.options {
  max-height: calc(88px * 10);
  overflow-y: auto;
}

.option {
  min-height: 88px;
  line-height: 88px;
  color: #666;
  background: #FFFFFF;
  border-bottom: 1px solid #eee;
  font-size: 34px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.option:last-child {
  border-bottom: none;
}

.option:active {
  background: #eee;
}

.radio-icon {
  width: 28px;
  height: 28px;
}

.not-selected {
  width: 28px;
  min-width: 28px;
  height: 28px;
  border: 4px solid #C4C7DB;
  border-radius: 6px;
}

.name {
  margin-left: 20px;
  line-height: 1.5em;
  vertical-align: middle;
  display: inline-block
}

.value {
  line-height: 1.5em;
  vertical-align: middle;
  display: inline-block
}
.collapse {
  background: #D8DAFF;
  color: #464CE7;
  border-radius: 100px;
  padding: 0 10px;
}
.required{
  color: #b9b9b9;
}
</style>
