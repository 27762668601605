<template>
  <van-cell :title="title" class="location" v-if="!justIcon">
    <template #default>
      <div class="cell-value">
        <input  v-bind="$attrs" type="text" v-model="value_" :placeholder="placeholder" @change="change" @input="codeInput"/>

        <img class="scan-icon" src="@/assets/images/icon_sys.png" @click="scan" alt="">
      </div>
    </template>
  </van-cell>
  <img v-else class="icon" src="@/assets/images/home/icon_sm.png" @click="scan" alt="">
</template>

<script>
import wx,{verification} from "@/utils/wxPermissionValidation";

export default {

  name: "ScanCode",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'IMEI号'
    },
    placeholder: {
      type: String,
      default: '请手动输入或扫码IMEI号'
    },
    justIcon: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler(value) {
        this.value_ = value
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      value_: ''
    }
  },
  methods: {
    scan() {
      console.log(111)
      verification(['scanQRCode'] ).then(()=>{
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: res => {
            let resultStr=res.resultStr||''
            let result=resultStr.split(",");
            this.value_ =result.length===2?result[1]:result[0];

            this.$emit('change', this.value_)
          }
        });
      })
    },
    change(e) {
      this.$emit('change', this.value_)
    },
    codeInput(e){
      this.$emit('myInput',e)
    }
  }
}
</script>

<style scoped lang="less">
input {
  text-align: right;
  width: 380px;
}
.scan-icon {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}
.icon{
  width: 46px;
  height: 46px;
}
.cell-value {
  white-space: nowrap;
}
.cell-value {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.cell-value input {
  flex: 1;
}
.location{
  display: flex;
}
.van-cell__title{
  flex: 0 200px;
}
</style>
