<template>
  <div :class="isExaple?'pic-uploader':'pic-uploader2'" ref="uploader">
    <van-uploader
        v-model="fileList"
        :max-count="1"
        :after-read="afterFileRead"
        :before-delete="beforePicDelete">
      <img src="@/assets/images/register/icon_sctp.png" alt="">
    </van-uploader>
    <div class="example" v-if="isExaple">
      <img :src="example" alt="" @click="$imagePreview([example])">
      <div class="text">示例图</div>
    </div>
  </div>
</template>

<script>
import {imageUp} from "@/api/register";
import {compression} from "@/components/uploader/imageCompression";

export default {
  name: "PicUploader",
  props: {
    src: {
      type: String,
      default: ''
    },
    example: {
      type: String,
      default: require('@/assets/images/register/example.png')
    },
    onlyCamera: {
      type: Boolean,
      default: true
    },
    isExaple: {//是否需要示例图
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      fileList: [],
      url: '',
      isReUpload: false
    }
  },
  watch: {
    src: {
      handler(src) {
        if (this.isReUpload) return
        if (!src) {
          this.fileList=[]
          return
        }
        this.fileList = [{url: src}]
      },
      immediate: true
    },
    onlyCamera() {
      this.setOnlyCamera()
    }
  },
  mounted() {
   this.setOnlyCamera()
  },
  methods: {
    setOnlyCamera() {
      let input= this.$refs.uploader.querySelector('.van-uploader__input')
      if(!input){
       return
      }
      if (this.onlyCamera) {
        try {
          input.setAttribute("capture", "camera");
        }catch (err) {
          console.log(err)
        }
      }else {
        input.removeAttribute("capture");
      }
    },
    afterFileRead: function (data) {
      console.log(data)
      compression(data.content, data.file.name, (file) => {
        imageUp(file).then(res => {
          this.$emit('change', res.data.url)
        }).catch(err => {
          console.log(err)
          this.fileList = []
          this.$toast(err && err.msg || '上传失败')
        }).finally(()=>{
          this.$nextTick(()=>{
            this.setOnlyCamera()
          })
        })
      });
    },
    beforePicDelete() {
      this.url = ''
      this.fileList = []
      this.$emit('change', '')
      if (!this.isReUpload) {
        this.isReUpload = true
      }
      this.$nextTick(()=>{
        this.setOnlyCamera()
      })
    }
  }
}
</script>

<style scoped lang="less">
.pic-uploader {
  margin-bottom: 20px;
  width: 100%;
}

.pic-uploader, .example {
  display: flex;

  .van-uploader {
    margin-right: 30px;
    height: 330px;
  }

  ::v-deep img {
    width: 330px;
    height: 330px;
  }
}
.pic-uploader2 {
  display: flex;

  .van-uploader {
    margin-right: 20px;
    height: 200px;
  }

  ::v-deep img {
    width: 200px;
    height: 200px;
  }
}


::v-deep .van-uploader__preview {
  margin: 0;
}

::v-deep .van-uploader__preview-image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.example {
  position: relative;
  border-radius: 10px;

  .text {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-size: 14px;
    width: 110px;
    height: 45px;
    background: #60ACF6;
    line-height: 45px;
    text-align: center;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 15px;
  }

  img {
    border-radius: 15px;
  }
}
</style>
