<template>
  <div class="select-search-cell">
    <van-cell
        :title="title"
        is-link
        border
        @click="click"
        ref="cell"
        :class="{expand,up:direction==='up',down:direction==='down'}">
      <template slot="default"></template>
      <input type="text"
             :disabled="disabled"
             v-model="inputValue"
             :placeholder="placeholder_?placeholder_:placeholder"
             class="input"
             @input="input"
             @focus="focus"
             @blur="blur">
    </van-cell>

    <div class="options" :class="{expand,up:direction==='up',down:direction==='down'}">
      <div class="option"
           @click="select(opt)"
           v-for="(opt,index) in options_"
           :class="{current:current===opt}"
           :key="index">{{ opt[labelKey] }}
      </div>
      <div class="option empty" v-if="!options_.length">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectSearchCell",
  //v-model
  model: {
    prop: 'value',
    event: 'model'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [Object,String,Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    idKey: {
      type: String,
      default: 'id'
    },
    maxRow: {
      type: Number,
      default: 5
    },
    remote:{
      type:Boolean,
      default:false
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      expand: false,
      current: {},
      inputValue: '',
      direction: 'down',
      options_: [],
      placeholder_: '',
      timer:null
    }
  },
  mounted() {
    let arrowEl = this.$refs.cell.querySelector('.van-icon')
    this.arrowEl = arrowEl
    arrowEl.style.transition = 'all 0.1s'

    //监听页面点击,点击页面其他部分则关闭options
    document.addEventListener('click', this.pageClick)
  },
  beforeDestroy() {
    //摧毁时移除事件
    document.removeEventListener('click', this.pageClick)
  },
  watch: {
    options: {
      handler(options) {
        if(!this.remote){
        this.options_ = options
        }
        if (this.value&&options.length) {
          this.setValue(options,this.value)
        }
      },
      immediate: true,
      deep:true
    },
    value:{
      handler(value) {
        if (!value || (typeof value === 'object' && !Object.keys(value).length)) {
          this.placeholder_ = '';
          this.inputValue = '';
          this.current = {};
        }
        if (this.options.length) {
          this.setValue(this.options,value)
        }
      },
      immediate:true,
      deep:true
    }
  },
  methods: {
    setValue(options, value) {
      if (this.idKey) {

        let filters=options.filter(item=>{
          return item[this.idKey]==value
        })
        this.current=filters.length?filters[0]:{}
        this.inputValue=this.current[this.labelKey]
      }else if (this.labelKey) {
        let filters= options.filter(item => {
          return item[this.labelKey] == value
        })
        this.current =filters.length?filters[0]:{}
        this.inputValue=this.current[this.labelKey]
      } else {
        this.inputValue=value
        this.current=value
      }
    },
    pageClick() {
      this.expand = false
    },
    click() {
      if (this.disabled) {
        return
      }
      let expand=this.expand;

      setTimeout(()=>{
        if (!expand) {
          this.calculateDirection()
        }
        this.expand = !expand;
      },400)
    },
    input() {
      if(this.remote){
        clearTimeout(this.timer);
        this.timer=null

        let handler= ()=>{
          this.$emit('getOptions',{
            value:this.inputValue,
            done:(options)=>{
              this.options_=options
            }});
        }

        this.timer= setTimeout(()=>{
          handler()
        },300)
      }else {
        if (this.inputValue) {
          this.options_ = this.options.filter(item => {
            return item[this.labelKey].toLowerCase()
                .indexOf(this.inputValue.toLowerCase()) > -1;
          });
          this.expand=true;
        } else {
          this.options_ = this.options;
          this.expand=false;
        }
      }

    },
    calculateDirection() {
      let {scale} = this.$store.state
      let optionsHeight = 70 * scale * (this.options_.length > this.maxRow ? this.maxRow : this.options_.length) +/*多30px,修改搜.options.expand*/ 30 * scale
      let cellBottom = this.$refs.cell.getBoundingClientRect().bottom
      //如果屏幕高度 - cell底部高度 - options高度为负数,options往上展示
      if (window.innerHeight - cellBottom - optionsHeight < 0) {
        this.direction = 'up';
      } else {
        this.direction = 'down';
      }
    },
    select(opt) {
      this.expand = false
      if (this.current[this.idKey] === opt[this.idKey]) {
        this.current={}
        this.inputValue=''
        this.$emit('model','')
        this.$emit('change', {})
        if (this.remote) {
          this.options_=[]
        }
        return;
      }
      this.current = opt;
      this.inputValue = opt[this.labelKey]
      this.$emit('model',this.idKey?opt[this.idKey]:this.labelKey?opt[this.labelKey]:opt)
      this.$emit('change',opt)
    },
    focus() {
      this.inputValue = ''
      this.placeholder_ = this.current[this.labelKey]
    },
    blur() {
      this.inputValue = this.current[this.labelKey]
      this.placeholder_ = ''
      if (!this.remote) {
        setTimeout(()=>{
          this.options_=this.options;
        },40)
      }
    }
  }
}
</script>

<style scoped lang="less">
.select-search-cell {
  position: relative;
}

.options {
  position: absolute;
  right: 0;
  z-index: 999;
  max-height: 0;
  transition: max-height 0.1s;
  overflow: hidden;
  padding: 0 30px;
  overflow-y: scroll;
  background: #FFFFFF;
  box-shadow: 0 0 10px @base-color;
  border-radius: 20px;
  width: 500px;
}

.options.expand {
  /*多30px使用户知道还有更多*/
  max-height: 380px;
}

.options.up {
  bottom: 90px;
}

.options.down {
  top: 90px;
}

.option {
  height: 70px;
  line-height: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 28px;
  color: @text-color;
}

.option.empty, .option.loading {
  width: 100%;
  text-align: center;
  color: @gray-dark;
  font-size: 28px;
}

.option.current {
  color: @base-color;
}


.van-cell__value {
  color: @text-color;
}

.no-value .van-cell__value {
  color: @gray-dark;
}

.input {
  width: 100%;
  text-align: right;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
}
</style>
<style lang="less">
.select-search-cell > .van-icon-arrow {
  transform: rotate(0deg);
}

.select-search-cell > .expand.down .van-icon-arrow {
  transform: rotate(90deg);
}

.select-search-cell > .expand.up .van-icon-arrow {
  transform: rotate(-90deg);
}
</style>
