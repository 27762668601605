<template>
<div class="order-wrapper">
  <img :src="imgs[status+'']" class="status-img" alt="">
  <div class="content">
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  name: "OrderWrapper",
  props:{
    status:{
      type:[Number,String],
      default:''
    }
  },
  data() {
    return{
      imgs:{
        '0':require('@/assets/images/Apply/img_shz_.png'),
        '1':require('@/assets/images/Apply/img_shtg.png'),
        '2':require('@/assets/images/Apply/img_shwtg_.png'),
        '3':require('@/assets/images/Apply/img_ysx_.png'),
        '4':require('@/assets/images/Apply/img_qydwc.png'),
        '5':require('@/assets/images/Apply/img_shtg.png'),
        '6':require('@/assets/images/Apply/img_shtg.png'),
        '7':require('@/assets/images/Apply/img_shtg.png'),
      }
    }
  }
}
</script>

<style scoped>
.status-img {
  width: 100vw;
}
.content {
  position: absolute;
  width: 100vw;
  top: 140px;
  background: #fff;
  border-radius: 40px 40px 0 0;
  padding: 0 30px 60px;
}
</style>
