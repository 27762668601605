<template>
  <div class="notice">
    <div v-html="content" class="content rich-text" v-if="content">
    </div>
    <div class="content" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notice",
  props: {
    content: {type: String, default: ''},
    icon: {type: String, default: ''},
  }
}
</script>

<style scoped lang="less">
.notice {
  .content {
    width: 100%;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 44px;
    background: #F6F8FE;
    border-radius: 16px;
    padding: 24px;
    text-align: justify;
    word-break:break-all;

    ::v-deep img{
      max-width:100%;
    }
  }
}
</style>
