<template>
  <div class="region-selector">
    <van-cell :title="title" is-link border @click="isShow=!isShow">
      <template #default>
        <template v-if="date">{{date}}</template>
        <span v-else style="color: #999">{{placeholder}}</span>
      </template>
    </van-cell>
    <!--  van-cell后面有标签才会出现下划线  -->
    <div></div>
    <van-popup v-model="isShow" round position="bottom">
      <van-datetime-picker
          v-model="date_"
          type="date"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="dateConfirm"
      />
    </van-popup>
  </div>

</template>

<script>
export default {
  name: "DatePicker",
  model: {
    event: 'change',
    prop:'date'
  },
  props: {
    title: {
      type: String,
      default: '日期选择'
    },
    placeholder: {
      type: String,
      default: '请选择日期'
    },
    minDate: {
      type: [String, Date],
      default() {
        return new Date(0)
      }
    },
    maxDate: {
      type: [String, Date],
      default() {
        return new Date()
      }
    },
    date: {
      type: [String, Date],
      default() {
        return new Date()
      }
    },
  },
  data() {
    return {
      isShow: false,
      date_: new Date()
    }
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    dateConfirm() {
      this.isShow=false
      let date=new Date(this.date_)
      let month=date.getMonth()+1
      let day=date.getDate()
      let dateStr=`${date.getFullYear()}-${month<10?'0'+month:month}-${day<10?'0'+day:day}`
      this.$emit('change',dateStr)
    }
  }
};
</script>

<style scoped>

</style>
