<template>
  <div>
    <van-cell :title="title" class="location" is-link @click="verifyAgain">
      <template #default>
        <div class="cell-value">
          <!--          <input type="text" v-model="value" placeholder="请手动输入或点击自动获取"/>-->
          <div class="city">{{ address.city }}</div>
          <span>
        <img class="location-icon" src="@/assets/images/icon_dwx.png" alt=""><span style="color:var(--base)">定位</span>
      </span>
        </div>
      </template>
    </van-cell>
  </div>
</template>

<script>
import AMap from 'maps'

import wx from 'jweixin-js'
import {verification} from "@/utils/wxPermissionValidation";

export default {
  name: "GetAddress",
  model: {
    prop: 'address',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: '维修城市'
    },
    address: {
      type: Object,
      default() {
        return {
          city: '',
          detail:''
        }
      }
    }
  },
  data() {
    return {
      value: '',
      latitude: '',
      longitude: '',
      isVerified:false
    }
  },
  watch: {
    value(value) {
      this.$emit('change', value)
    }

  },
  mounted() {
    this.verify()
  },
  methods: {
    verify() {
      if (process.env.NODE_ENV === 'production') {
        verification(['openLocation', 'getLocation']).then(() => {
          this.isVerified=true
          if (!this.latitude && !this.longitude) {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: res => {
                this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                this.getAddress()
              }
            });
          } else {
            this.getAddress()
          }
        })

      } else {
        this.getAddress(104.062035, 30.596188)
      }
    },
    verifyAgain() {
      if (!this.isVerified) {
        this.verify()
      }
    },
    getAddress(longitude = this.longitude, latitude = this.latitude) {
      AMap.plugin('AMap.Geocoder', () => {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '010'
        })
        geocoder.getAddress([longitude, latitude], (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.$emit('change',{city:result.regeocode.addressComponent.city,detail:result.regeocode.formattedAddress} )
          } else {
            this.$toast('无法获取地址')
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
.cell-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.location {
  .van-cell__value {
    flex: 4;
  }

  input {
    text-align: right;
    background: transparent;
    width: 13em;
  }

  .location-icon {
    width: 38px;
    height: 47px;
    position: relative;
  }
}

.city {
  padding-right: 20px;
}
</style>
