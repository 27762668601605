import request from "@/utils/http";

export function getRegion(params = {}) {
    return request({
        url: '/index/getRegion',
        method: 'post',
        data: params
    })
}
export function getRegion2(params = {}) {
    return request({
        url: '/index/getRegion2',
        method: 'post',
        data: params
    })
}

export function getGroup(params = {}) {
    return request({
        url: '/Group/getGroup',
        method: 'post',
        data: params
    })
}

export function getGroupShop(params = {}) {
    return request({
        url: '/Group/getGroupShop',
        method: 'post',
        data: params
    })
}

export function getWxsdk(params = {}) {
    return request({
        url: '/Index/getWxsdk',
        method: 'post',
        params: params,
        isLoading:false
    })
}

export function repairManagerList(params = {}) {
    return request({
        url: '/Index/repairManagerList',
        method: 'get',
        params: params
    })
}

export function repairShopList(id) {
    return request({
        url: '/Index/repairShopList?remanager_id=' + id,
        method: 'get'
    })
}

export function getFinishType() {
    return request({
        url: '/Repair/getFinishType',
        method: 'get'
    })
}

export function finishType() {
    return request({
        url: '/Repair/finishType',
        method: 'get'
    })
}

export function getFinishMode() {
    return request({
        url: '/Repair/getFinishMode',
        method: 'get'
    })
}

export function imageUp(file) {
    let data = new FormData();
    data.append('file', file)
    return request({
        url: '/Uploadify/imageUp',
        method: 'post',
        data
    })
}

export function addShopMember(params = {}) {
    return request({
        url: '/Group/addShopMember',
        method: 'post',
        data: params
    })
}

export function setRepair(params = {}) {
    return request({
        url: '/Index/setRepair',
        method: 'post',
        data: params
    })
}
