<template>
  <div>
    <van-cell :title="title" class="location" is-link @click="verifyAgain">
      <template #default>
        <div class="cell-value">
          <input type="text" v-model="value" :placeholder="placeholder"/>
          <span @click="getAddress">
        <img class="location-icon" src="@/assets/images/icon_dwx.png" alt=""><span style="color:var(--base)">定位</span>
      </span>
        </div>
      </template>
    </van-cell>
  </div>
</template>

<script>

import eventBus from "@/components/cell/GetAddress2/eventBus";
import wx,{verification} from "@/utils/wxPermissionValidation";

export default {
  name: "GetAddress",
  model: {
    prop: 'address',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: '门店地址'
    },
    placeholder: {
      type:String,
      default:'请手动输入或点击选择'
    },
    address: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: '',
      isShowSelectAddress: false,
      latitude: '',
      longitude:'',
      isVerified:false
    }
  },
  watch: {
    value(value) {
      this.$emit('change', value)
    }

  },
  beforeRouteLeave (to, from , next) {
    if (this.isShowSelectAddress) {
      this.isShowSelectAddress=false
    }else {
      next();
    }
  },
  mounted() {
   eventBus.$on('selectedAddress', data=>{
      let {address}=data
      this.$emit('change', address)
      this.value=address
      console.log(data)

    });
  },
  methods: {
    getAddress() {
      if (process.env.NODE_ENV === 'production') {
        verification(['openLocation'] ).then(()=>{
          this.isVerified=true
          if (!this.latitude && !this.longitude) {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: res=> {
                this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                // var speed = res.speed; // 速度，以米/每秒计
                // var accuracy = res.accuracy; // 位置精度
                this.$router.push({path:`/SelectAddress/${this.longitude}/${this.latitude}`})
              }
            });
          }else {
            this.$router.push({path:`/SelectAddress/${this.longitude}/${this.latitude}`})
          }
        })
      }else {
        this.$router.push({path:`/SelectAddress/${104.062035}/${30.596188}`})
      }
    },
    verifyAgain() {
      if (!this.isVerified) {
        this.getAddress()
      }
    }
  }
}
</script>

<style scoped lang="less">
.cell-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.location {
  .van-cell__value {
    flex: 4;
  }

  input {
    text-align: right;
    background: transparent;
    width: 13em;
  }

  .location-icon {
    width: 38px;
    height: 47px;
    position: relative;
  }
}
</style>
