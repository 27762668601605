<template>
  <div class="pic-uploader" ref="uploader">
    <van-uploader
        v-model="fileList"
        :max-count="1"
        accept="video/*"
        :after-read="afterFileRead"
        :before-delete="beforePicDelete">
      <img src="@/assets/images/icon_csp.png" alt="" class="icon">
      <video v-if="fileList.length" v-show="!isUploading" ref="video" class="video" slot="preview-cover"
             :controls="isUploading?false:'controls'" :src="src"></video>
      <div class="progress" v-if="isUploading" slot="preview-cover">
        <svg>
          <circle cx="50%" cy="50%" r="50px"></circle>
          <circle cx="50%" cy="50%" r="50px"
                  :style="{'stroke-dashoffset':(628  * this.$store.state.scale * (progress / 100))+'px'}"></circle>
        </svg>
        <div class="progress-text">{{ progress }}%</div>
      </div>
    </van-uploader>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VideoUploader",
  props: {
    src: {
      type: String,
      default: ''
    },
    onlyCamera:{
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      fileList: [],
      url: '',
      isUploading: false,
      progress: 0
    }
  },
  watch: {
    src: {
      handler(src) {
        if (!src) return;
        this.fileList = [{url: src}]
      },
      immediate: true
    },
    onlyCamera() {
      this.setOnlyCamera()
    }
  },
  mounted() {
    this.setOnlyCamera()
  },
  methods: {
    setOnlyCamera() {
      let input= this.$refs.uploader.querySelector('.van-uploader__input')
      if(!input){
        return
      }
      if (this.onlyCamera) {
        try {
          input.setAttribute("capture", "camera");
        }catch (err) {
          console.log(err)
        }
      }else {
        input.removeAttribute("capture");
      }
    },
    afterFileRead(data) {
      let {file} = data
      if (!/^video\/.+$/.test(file.type)) {
        this.$toast("视频格式不正确，只支持“.mp4”");
        this.fileList = []
        return;
      }
      let size = file.size / 1024 / 1024;
      if (size > 300) {
        this.$toast("视频大小不能超过300M");
        this.fileList = []
        return;
      }
      this.$nextTick(() => {
        let video = this.$refs.video
        video.src = URL.createObjectURL(file);
        this.upload()
        // video.addEventListener('loadeddata', function() {
        //
        // })
      })
    },
    upload() {
      this.isUploading = true
      let formData = new FormData();
      formData.append('file', this.fileList[0].file)
      axios.post(process.env.VUE_APP_BASE_URL + '/api/Uploadify/fileUp', formData, {
        timeout:10000000,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          this.progress = (progressEvent.loaded / progressEvent.total * 100 | 0)
        }
      }).then(res => {
        console.log('res',res)
        if (res.data.code === 1) {
          this.$emit('change', res.data.data.url)
          this.isUploading = false
        } else {
          this.$emit('change', '')
          this.fileList = []
          this.$toast(res && res.data && res.data.msg || '上传视频失败，请重新上传')
          this.isUploading = false
        }
      }).catch(err => {
        console.log('err',err)
        this.$emit('change', '')
        this.fileList = []
        this.$toast(err&&err.data && err.data.msg || '上传视频失败，请重新上传')
        this.isUploading = false
      }).finally(()=>{
        this.$nextTick(()=>{
          this.setOnlyCamera()
        })
      })
    },
    beforePicDelete() {
      this.url = ''
      this.fileList = []
      this.$emit('change', '')
      this.$nextTick(()=>{
        this.setOnlyCamera()
      })
    }
  }
}
</script>

<style scoped lang="less">
.pic-uploader {
  height: 345px;
  width: calc(100vw - 60px);
  border: 6px dashed #B6B9D0;
  border-radius: 16px;
  box-sizing: border-box;

  ::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
    height: 345px;
    width: calc(100vw - 60px);
    position: relative;
  }

  .icon {
    width: 134px;
    height: 154px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video {
    height: 350px;
    width: calc(100vw - 55px);
    background: #000;
    border-radius: 16px;
  }

  ::v-deep .van-uploader__file {
    height: 350px;
    width: calc(100vw - 60px);
    position: relative;
    left: -6px;
    top: -6px;
    border-radius: 16px;
  }
}

::v-deep .van-uploader__preview-delete {
  top: -25px;
  right: -15px;
}

.progress {
  height: 350px;
  width: calc(100vw - 60px);
  position: relative;
  left: -6px;
  top: -6px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}

svg {
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
}

.progress-text {
  position: absolute;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  font-size: 36px;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}

circle {
  fill: none;
  stroke: #51a2f6;
  stroke-width: 16px;
}

circle:last-of-type {
  stroke-width: 16px;
  stroke: #eee;
  stroke-dashoffset: 628px;
  stroke-dasharray: 628px;
}
</style>
