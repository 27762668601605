<template>
  <div class="protocol" @click="$emit('change',!isReadProtocol)">
    <div class="checkbox" :class="{checked:isReadProtocol}"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  model: {
    prop:'isReadProtocol',
    event:'change'
  },
  name: "Protocol",

 props:{
   isReadProtocol:{
     type:Boolean,
     default:false
   },
 }
}
</script>

<style scoped lang="less">
.protocol {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;

.checkbox{
  width: 30px;
  height: 30px;
  border: 2px solid #DAD8D8;
  border-radius: 15px;
  margin-right: 14px;

&.checked{
   border:none;
   background-image: url("~@/assets/images/register/icon_yty.png");
   background-repeat: no-repeat;
   background-size: 30px 30px;
 }
}
}
</style>
