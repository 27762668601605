import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/home'
    }, {
        path: '/',
        redirect: '/home'
    },
    //用于授权跳转的路由 /auth?path=%2FRepairInfoAdd%2F3&code=001llqll28Otn74X3rll23nz3c4llqlt
    {
        path: '/auth',
        name: 'auth',
        component: () => import('../views/auth')
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            role: 'staff',
            // keepAlive: true
        },

        component: () => import('../views/Home')
    },
    {
        path: '/Demos',
        name: 'Demos',
        meta: {
            keepAlive: true
        },
        component: () => import('../views/Demos')
    },
    {
        path: '/register/:type',  //type:  repairman/staff 商家/员工
        name: 'Register',
        props: true,
        meta: {
            keepAlive: true
        },
        component: () => import('../views/Register'),
    },
    {
        path: '/RepairInfoAdd/:id', //id: 理赔单id
        name: 'RepairInfoAdd',
        props: true,
        meta: {
            role: 'repairman'
        },
        component: () => import('../views/RepairInfo/add'),
    },
    {
        path: '/RepairInfoDetail/:id', //id:维修订单id
        name: 'RepairInfoDetail',
        props: true,
        meta: {
            role: 'repairman'
        },
        component: () => import('../views/RepairInfo/detail'),
    },
    {
        path: '/claimInfo/:id',
        name: 'claimInfo',
        props: true,
        meta: {
           noVerify:true,
            keepAlive: true,
        },
        component: () => import('../views/ClaimInfo/add'),
    },
    {
        path: '/contract',
        name: 'contract',
        props: true,
        meta: {
           noVerify:true,
        },
        component: () => import('../views/ClaimInfo/contract'),
    },
    {
        path: '/signature',
        name: 'signature',
        props: true,
        meta: {
           noVerify:true,
        },
        component: () => import('../views/ClaimInfo/signature'),
    },
    {
        path: '/orderHome/index',
        name: 'orderHome',
        meta: {
            title: '订单查询',
            keepAlive: true,
            isUseCache: false,
            noVerify:true
        },
        component: () => import('../views/orderHome/index')
    },
    {
        path: '/orderHome/detail',
        name: 'orderHomeDetail',
        meta: {
            title: `订单查询结果`
        },
        component: () => import('../views/orderHome/detail')
    },
    {
        path: '/replaceIMEI/apply/:phoneType', //phoneType 0全部1苹果2安卓
        name: 'ReplaceIMEIApply',
        props: true,
        meta: {
            title: '更换IMEI申请'
        },
        component: () => import('../views/ReplaceIMEI/apply')
    },
    {
        path: '/replaceIMEI/detail/:id', //id:订单id
        name: 'ReplaceIMEIDetail',
        props: true,
        meta: {
            title: '更换IMEI订单详情'
        },
        component: () => import('../views/ReplaceIMEI/detail')
    },
    {
        path: '/RightApply/addApply',
        name: 'addApplyDetail',
        meta: {
            title: `${store.state.document_title}权益申请`
        },
        component: () => import('../views/RightApply/addApply')
    },
    {
        path: '/RightApply/detailsApply',
        name: 'detailsApplyDetail',
        meta: {
            title: `${store.state.document_title}权益订单`
        },
        component: () => import('../views/RightApply/detailsApply')
    },
    {
        path: '/RightApply/related',
        name: 'relatedDetail',
        meta: {
            title: `${store.state.document_title}关联申请记录`
        },
        component: () => import('../views/RightApply/related')
    },
    {
        path: '/RightApply/renew',
        name: 'RightApplyRenew',
        meta: {
            title: '服务续费'
        },
        component: () => import('../views/RightApply/renew')
    },
    {
        path: '/ClaimSettlement/selectType',
        name: 'ClaimSettlementSelect',
        meta: {
            title: '申请履约'
        },
        component: () => import('../views/ClaimSettlement/selectType')
    },
    // {
    //     path: '/ClaimSettlement/query/:type', //type: iphone/android/brokenScreen/androidYearChange/iphoneYearChange
    //     name: 'ClaimSettlementQuery',
    //     props: true,
    //     component: () => import('../views/ClaimSettlement/query')
    // },
    {
        path: '/ClaimSettlement/detail/:id', //id: 订单id
        name: 'ClaimSettlementDetail',
        props: true,
        component: () => import('../views/ClaimSettlement/detail')
    },
    {
        path: '/ClaimSettlement/apply/:type', //type: iphone/android/brokenScreen/androidYearChange/iphoneYearChange
        name: 'ClaimSettlementApply',
        props: true,
        component: () => import('../views/ClaimSettlement/apply')
    },
    {
        path: '/ChangeComplete/apply/:type/:id', //type: iphone/android/brokenScreen/androidYearChange/iphoneYearChange  id:
        name: 'ChangeCompleted',
        meta: {
            title: '换机完成照上传'
        },
        props: true,
        component: () => import('../views/ChangeComplete/apply/index')
    },
    {
        path: '/ChangeComplete/detail/:id', //id:换机完成id
        name: 'ChangeCompleteApply',
        meta: {
            title: '换机完成详情'
        },
        props: true,
        component: () => import('../views/ChangeComplete/detail')
    },
    {
        path: '/SelectAddress/:lng/:lat',    //:lng 经度 :lat 纬度
        name: 'SelectAddress',
        props: true,
        component: () => import('../components/cell/GetAddress2/SelectAddress')
    },
    {
        path: '/PhotoResubmission',
        name: 'PhotoResubmission',
        meta: {
            title: '照片补交'
        },
        props: true,
        component: () => import('../views/photoResubmission/index')
    },
    {
        path: '/Qianmi',
        name: 'Qianmi',
        meta: {
            title: '订单查询'
        },
        props: true,
        component: () => import('../views/Qianmi/index')
    },
    {
        path: '/QianmiList',
        name: 'QianmiList',
        meta: {
            title: '订单查询结果'
        },
        props: true,
        component: () => import('../views/Qianmi/list')
    },
    {
        path: '/CouponOrder',
        name: 'CouponOrder',
        meta: {
            title: '优惠券查询'
        },
        props: true,
        component: () => import('../views/CouponOrder/index')
    },
    {
        path: '/CouponOrder/useCoupon',
        name: 'useCoupon',
        meta: {
            title: '提交订单'
        },
        props: true,
        component: () => import('../views/CouponOrder/useCoupon')
    },
    {
        path: '/CouponOrder/couponOrderInfo',
        name: 'couponOrderInfo',
        meta: {
            title: '订单详情'
        },
        props: true,
        component: () => import('../views/CouponOrder/couponOrderInfo')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

let domain = window.location.href.match(/(http:\/\/|https:\/\/)[^/]+(?=\/)/)[0]
router.afterEach(async (to) => {
    //https://my.oschina.net/u/4381342/blog/3625843
    if (window.__wxjs_is_wkwebview) { // IOS
        if (window.entryUrl === '' || window.entryUrl === undefined) {
            window.entryUrl = domain +
                to.fullPath
            console.log('IOS', window.entryUrl)
        } else {
            console.log('IOS2', window.entryUrl)
        }
    } else { // 安卓
        window.entryUrl = domain +
            to.fullPath
    }

})

export default router
