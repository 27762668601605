/*
* 图片压缩
* */

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export function compression(path,filename, callback,quality=0.8) {
    let img = new Image()
    let width, height, scale

    img.src = path;
    img.onload = function () {
        scale = this.height / this.width
        width = this.width>375?this.width*quality:this.width
        height = width * scale

        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        let canvasW = document.createAttribute("width");
        canvasW.nodeValue = width;
        let canvasH = document.createAttribute("height");
        canvasH.nodeValue = height;
        canvas.setAttributeNode(canvasW);
        canvas.setAttributeNode(canvasH);
        ctx.drawImage(this, 0, 0, width, height);
        let base64 = canvas.toDataURL('image/jpeg', quality );
        callback(dataURLtoFile(base64,filename));
    };
}
