import request from "../utils/http";

//首页订单请求
export function HomeOrderList(params = {}) {
    return request({
        url: '/Apply/getOrderList',
        method: 'post',
        data: params
    })
}

// 订单查询类型获取
export function orderType(params = {}) {
    return request({
        url: '/index/orderType',
        method: 'get',
        params: params
    })
}

// 订单查询
export function orderQuery(params = {}) {
    return request({
        url: '/index/orderQuery',
        method: 'post',
        data: params
    })
}

//权益类型数组
export function insuranceType(params = {}) {
    return request({
        url: '/index/insuranceType',
        method: 'get',
        params: params
    })
}

// 品牌数组
export function getPhoneBrand(params = {}) {
    return request({
        url: '/index/getPhoneBrand',
        method: 'post',
        data: params
    })
}
// 碎屏险理赔新增的权益选择
export function brokenEquity(params = {}) {
    return request({
        url: 'Claims/equity',
        method: 'get',
        params: params
    })
}
// 获取手机裸机价格
export function getPhonePrice(params = {}) {
    return request({
        url: '/index/getPhonePrice',
        method: 'post',
        data: params
    })
}
// ************
export function queryImei(params = {}) {
    return request({
        url: '/Apply/queryImei',
        method: 'post',
        data: params
    })
}
export function selectGear(params = {}) {
    return request({
        url: '/Apply/selectGear',
        method: 'post',
        data: params
    })
}
// 获取员工对应商家的虚拟卡配置
export function getCardName(params = {}) {
    return request({
        url: '/Card/getCardName',
        method: 'post',
        data: params
    })
}
// 获取随机卡密
export function getCard(params = {}) {
    return request({
        url: '/Card/getCard',
        method: 'post',
        data: params
    })
}
// 手机机型数组
export function getPhoneModel(params = {}) {
    return request({
        url: 'index/getPhoneModel',
        method: 'post',
        data: params
    })
}
// 手机机型下的手机规格/内存
export function getPhoneRam(params = {}) {
    return request({
        url: 'index/getPhoneRam',
        method: 'post',
        data: params
    })
}

// 权益类型提交
export function setOrder(params = {}) {
    return request({
        url: '/Apply/setOrder',
        method: 'POST',
        data: params
    })
}
// 权益类型提交
export function setOrder2(params = {}) {
    return request({
        url: '/Apply/setOrder2',
        method: 'POST',
        data: params
    })
}

// 权益类型提交(编辑)
export function editOrder(params = {}) {
    return request({
        url: '/Apply/editOrder',
        method: 'POST',
        data: params
    })
}

// 权益订单获取详情
export function getOrderInfo(params = {}) {
    return request({
        url: '/Index/getOrderInfo',
        method: 'POST',
        data: params
    })
}

// 权益订单关联理赔订单
export function getClaimsList(params = {}) {
    return request({
        url: '/Apply/getClaimsList',
        method: 'POST',
        data: params
    })
}

export function getUserOpenid(params = {code:''}) {
    return request({
        url: '/index/getUserOpenid',
        method: 'POST',
        data: params
    })
}

export function checkOpenid(params={}) {
    return request({
        url: '/Index/checkOpenid',
        method: 'POST',
        data: params
    })
}

export function article(id) {
    return request({
        url: '/Index/article?cat_id='+id,
        method: 'GET'
    })
}

export function checkCdkey(params={}) {
    return request({
        url: '/Apply/checkCdkey',
        method: 'POST',
        data: params
    })
}

export function testce(params={}) {
    return request({
        url: '/index/testce',
        method: 'POST',
        data: params
    })
}
export function testce3(params={}) {
    return request({
        url: '/index/testce3',
        method: 'POST',
        data: params
    })
}
export function checkSon(params={}) {
    return request({
        url: '/Apply/checkSon',
        method: 'POST',
        data: params
    })
}
export function getRelationType(params={}) {
    return request({
        url: '/Group/getRelationType',
        method: 'POST',
        data: params
    })
}
export function setOrderMake(params={}) {
    return request({
        url: '/Group/setOrderMake',
        method: 'POST',
        data: params
    })
}

export function verifyGroup(params={}) {
    return request({
        url: '/Group/verifyGroup',
        method: 'POST',
        data: params
    })
}

export function verifyCard(params={}) {
    return request({
        url: '/Card/verifyCard',
        method: 'POST',
        data: params
    })
}

export default {
    HomeOrderList,
    orderType,
    orderQuery,
    insuranceType,
    setOrder,
    setOrder2,
    editOrder,
    getPhoneBrand,
    getPhoneModel,
    getOrderInfo,
    getClaimsList,
    getPhonePrice,
    queryImei,
    getCardName,
    getCard,
    checkCdkey,
    checkSon,
    getPhoneRam,
    brokenEquity
}
