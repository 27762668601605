<template>
  <div class="my-button">
    <van-button v-bind="$attrs" :style="{width: _width}" class="my-button" :type="type" round @click="click">
      <slot></slot>
    </van-button>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    width: {
      type: [Number, String],
      default: '100%'
    }
  },
  name: "MyButton",
  computed: {
    _width() {
      if (typeof this.width === 'number') {
        return this.width / 75 + 'rem';
      } else {
        return this.width
      }
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="less">
.van-button--primary {
  box-shadow: 0 6px 15px 1px rgba(87, 123, 181, 0.4);;
}

.van-button--default {
  box-shadow: 0px -2px 2px 0px rgba(87, 123, 181, 0.1);
}
</style>