import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //屏幕的缩放比
    scale:1,
    openid: '',
    role: '',
    userInfo: {},
    SerachValue:'',
    orderValue:'',
    document_title:''//页面标题
  },
  mutations: {
    setScale(state,scale) {
      state.scale=scale
    },
    setOpenid(state,openid) {
      state.openid=openid
    },
    setRole(state,role) {
      state.role=role
    },
    SerachValue(state,val) {
      state.SerachValue=val
    },
    orderValue(state,val) {
      state.orderValue=val
    },
    document_title(state,val) {
      state.document_title=val
    },
    setUserInfo(state, val) {
      state.userInfo=val
    }
  },
  actions: {
  },
  modules: {
  }
})
